<template>
  <div>
    <div class="pagination_wrap">
      <ul style="display: flex; justify-content: center;">
        <li>
          <button
            :disabled="page == 1"
            class="btn"
            id="left"
            @click="changePage('prev', '', '')"
          >
            Prev
          </button>
        </li>
        <li id="ca" class="" v-for="(item, index) in thePageNum" :key="index">
          <button
            :style="[item == '...' ? { padding: '0px !important' } : {}]"
            :disabled="item == page || item == '...'"
            class="btn charlesPagination"
            :class="[page == item ? 'activePage' : '']"
            @click="paginationAction(item)"
          >
            {{ item }}
          </button>
        </li>
        <li>
          <button
            :disabled="page == totalcount"
            class="btn"
            id="right"
            @click="changePage('', '', 'next')"
          >
            Next
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { Service } from "../store/service";
const Api = new Service();
import $ from "jquery";
// import mitt from 'mitt';

// let export default = pagination;
export default {
  name: "Pagination",
  components: {},
  // props: ["pageData"],
  // props: [dataFromChild],
  data() {
    return {
      page: 1,
      limit: 10,
      totalcount: "",
      item: null,
      pageUrl: "",
      data1: "",
      thePageNum: [],
      response: "",
      padding: "0px !important"
    };
  },
  // ready: function() {
  //   this.work();
  // },
  mounted() {
    this.pageUrl = Api.getPageURL();
    if (Object.keys(this.pageUrl).length != 0) {
      // Check here again for dynamic pasing of the page number
      if (this.pageUrl == "safetransactionspaginated") {
        this.page = 1;
      }
      this.getPageData();
    }
  },

  methods: {
    async getPageData() {
      this.pageUrl = Api.getPageURL();
      // console.log("Page url", this.pageUrl);
      await Api.getRequest(
        `${this.pageUrl}?page=${this.page}&perpage=${this.limit}`
      )
        .then(res => {
          // console.log("All Data", res.data.totalpages);
          this.totalcount = res.data.totalpages;
          this.$emit("pageData", res);
          this.pageBuild();
        })
        .catch(err => {
          console.log("Error", err);
        });
    },

    changePage(prev, dotted, next) {
      if (prev) {
        if (this.page > 1) {
          this.page--;
        }
        this.getPageData();
      }
      if (next) {
        if (this.page < this.totalcount) {
          this.page++;
        }
        this.getPageData();
      }
    },

    paginationAction(pageNumber) {
      this.page = pageNumber;
      this.limit = 10;
      this.getPageData();
    },

    pageBuild() {
      this.thePageNum = [];

      const viewportWidth =
        window.innerWidth || document.documentElement.clientWidth;
      // If there are only 6 pages or less in total, just display all pages without "..."
      if (this.totalcount <= 6 && viewportWidth >= 360) {
        for (let i = 1; i <= this.totalcount; i++) {
          this.thePageNum.push(i);
          // console.log("pageeeeeeeeeee", this.thePageNum);
          // console.log("Big screen");
        }
      }
      // Else dispaly with ...
      else {
        // Always print first page button
        this.thePageNum.push(1);
        // console.log("pageess", this.thePageNum);

        // Print "..." if currentPage is > 3
        if (this.page > 3) {
          this.thePageNum.push("...");
        }

        // special case where last page is selected...
        // Without this special case, only 2 buttons would be shown after ... if you were on last page
        if (this.page == this.totalcount) {
          this.thePageNum.push(this.page - 2);
        }

        // Print previous number button if currentPage > 2
        if (this.page > 2) {
          this.thePageNum.push(this.page - 1);
        }

        //Print current page number button as long as it not the first or last page
        if (this.page != 1 && this.page != this.totalcount) {
          this.thePageNum.push(this.page);
        }

        //print next page number button if currentPage < lastPage - 1
        if (this.page < this.totalcount - 1) {
          this.thePageNum.push(this.page + 1);
        }

        // special case where first page is selected...
        // Without this special case, only 2 buttons would be shown before ... if you were on first page
        if (this.page == 1) {
          this.thePageNum.push(this.page + 2);
        }

        //print "..." if currentPage is < lastPage -2
        if (this.page < this.totalcount - 2) {
          this.thePageNum.push("...");
        }

        //Always print last page button if there is more than 1 page
        if (this.totalcount > 1) {
          this.thePageNum.push(this.totalcount);
        }
      }
    },

    handlEvent(value) {
      // this.pageUrl = this.$store.state.pageUrl;
      this.page = value;
      this.limit = 10;
      //this.getPageData();
      this.getPageData();
      // console.log("Hell yeah", this.transactions);
      this.transactions = this.respoanse;
      // this.rebuildPagination(this.page, this.totalcount);
    }
    // PAGINATION SECTIONS ENDS
  }
};

// window.runPagination = function(data) {
//   // new Vue.$children[0].work()
//   pagination.methods.handlEvent(data);
// };

// export default pagination;
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}

// Pagination begins
.pagination_wrap {
  margin: 20px 0;
  #left {
    background: #2e4dbc;
    margin-right: 10px;
    color: #ffffff;
    border-radius: 50px;
    font-family: Gotham;
    // font-size: 16px;
  }
  #right {
    background: #2e4dbc;
    margin-left: 10px;
    color: #ffffff;
    border-radius: 50px;
    font-family: Gotham;
    // font-size: 16px;
  }
}

ul {
  list-style-type: none;
  display: flex;
  justify-content: center;
  padding-left: 0;
}

button {
  outline: 0 !important;
  box-shadow: none !important;
}

.activePage {
  background-color: #ffb100;
  box-shadow: none;
  outline: 0;
  font-family: Gotham;
}
.activePage:disabled {
  background-color: #ffb100;
  opacity: 1;
  cursor: not-allowed;
}
@media screen and (max-width: 599px) {
  #right,
  #left {
    font-size: 11px !important;
  }
  .charlesPagination,
  .activePage {
    padding: 0.1rem 0.5rem !important;
    font-size: 14px !important;
  }
}

// Pagination ends
</style>
