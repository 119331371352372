<template>
  <div>
    <div>
      <SmallNav />
      <div class="container">
        <div class="transaction_header">
          <h4 class="back_text" @click="navigateBack">
            <img src="../assets/images/safe.svg" alt="safe" />
            <span>Back</span>
          </h4>
          <div class="projects_link">
            <router-link to="/dashboard/safeprojects">
              <h6 class="btn">
                <img src="../assets/images/house-safe.svg" alt="projects" />
                Projects
              </h6>
            </router-link>
          </div>
        </div>

        <div v-if="LoadData">
          <PageLoader></PageLoader>
        </div>
        <div v-else>
          <div class="table-wrap clearfix" v-if="transactions.length > 0">
            <div class="recent_transactions">
              <h5>Recent Transactions</h5>
            </div>
            <div class="table-responsive">
              <table class="table seperate_border">
                <tbody v-for="(item, index) in transactions" :key="index">
                  <tr>
                    <th scope="row">
                      <div class="green-dot" v-if="item.color == 'green'"></div>
                      <div
                        class="yellow-dot"
                        v-if="item.color == 'yellow'"
                      ></div>
                      <div class="red-dot" v-if="item.color == 'red'"></div>
                    </th>
                    <td scope="row">
                      <div v-if="item.type == 'credit'" class="green-dot"></div>
                      <div v-if="item.type == 'debit'" class="red-dot"></div>
                    </td>
                    <th style="font-weight: bold;">
                      &#8358;{{ Number(item.amount).toLocaleString() }}
                    </th>
                    <td>{{ item.description }}</td>
                    <td>{{ item.created_at | moment }}</td>
                    <td>{{ item.channel }}</td>
                    <th>{{ item.transaction_reference }}</th>
                  </tr>
                </tbody>
              </table>
            </div>
            <Pagination
              v-if="transactions"
              @pageData="dataFromChild($event)"
            ></Pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SmallNav from "./SmallNav";
import PageLoader from "./PageLoader.vue";
import { Service } from "../store/service";
import Pagination from "../components/Pagination.vue";
const Api = new Service();
import $ from "jquery";
import moment from "moment";

export default {
  name: "SafeTranactionHistory",
  // props: ["numpage"],
  components: {
    SmallNav,
    PageLoader,
    Pagination
  },
  data() {
    return {
      LoadData: false,
      transactions: "",
      page: 1,
      limit: 10,
      totalcount: "",
      thePageNum: [],
      respoanse: ""
    };
  },

  mounted() {
    this.user = JSON.parse(localStorage.getItem("shelta-user"));
    // this.rebuildPagination();
    this.getTransactions();
    // console.log("Previous route", this.$router.history._startLocation);
    // this.numpage = 2;
    //  setTimeout(() => {
    //     this.dataFromChild()
    //   }, 5000);
  },
  methods: {
    async getTransactions() {
      this.LoadData = true;
      try {
        await Api.getRequest(
          `safetransactionspaginated?page=${this.page}&perpage=${this.limit}`
        )
          .then(res => {
            this.LoadData = false;
            // console.log("transactions", res);
            // this.respoanse = res.data.transactions;
            this.totalcount = res.data.totalpages;
            // console.log("Total pages", this.totalcount);
            this.pageUrlGlobal();
            this.transactions = res.data.transactions;
            // this.pageBuild();
          })
          .catch(err => {
            console.log("err", err);
            this.LoadData = false;
          });
      } finally {
        // console.log("closed")
      }
    },

    dataFromChild(data) {
      // this.pageData = dataFromChild;
      // console.log("Data from child", data);
      this.transactions = data.data.transactions;
    },
    pageUrlGlobal() {
      Api.pushPageURL(`safetransactionspaginated`);
    },

    navigateBack() {
      this.$router.go(-1);
    },
    openPanel: function(id) {
      // console.log("Index", id);
      this.panel = id;
      const coll = document.getElementsByClassName("collapsible");
      let i;

      for (i = 0; i < coll.length; i++) {
        coll[i].addEventListener("click", function() {
          // console.log("Index", coll);
          this.classList.toggle("active");
          const content = this.nextElementSibling;
          if (content.style.maxHeight) {
            // console.log("Next sibline", content);
            content.style.maxHeight = null;
          } else {
            content.style.maxHeight = content.scrollHeight + "px";
            // console.log("Next sibline2", content);
          }
        });
      }
    },
    keytab(event) {
      const nextInput = event.srcElement.nextElementSibling; // get the sibling element
      const previousInput = event.srcElement.previousElementSibling;

      const target = event.target || event.srcElement;
      // const id = target.id;
      // console.log("Key pressed", event.key); // prints undefined
      if (event.key === "Backspace") {
        // console.log("back", target);
        // previousInput === null;
      }

      if (nextInput === null) {
        // check the maxLength from here
        // console.log("next check", nextInput);
        return;
      } else {
        nextInput.focus();
      } // focus if not null
    }
  },
  filters: {
    moment: function(date) {
      return moment(date).format("MMMM Do YYYY, h:mm:ss a");
    }
  }
};

// window.runPagination = function(data) {
//   // new Vue.$children[0].work()
//   pagination.methods.handlEvent(data);
// };

// export default pagination;
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$yellow: #ffb100;
$tatiary: #a2b0e3;
$black: #000000;
$limeGreen: #3ec250;

@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
}

.transaction_header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 20px;
  margin-bottom: 10px;
}

.back_text {
  cursor: pointer;
  img {
    background: #3059eb;
    border-radius: 3px;
    padding: 5px;
  }
  font-family: Gotham;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: $black;
  span {
    color: #1765ca;
    padding-left: 5px;
  }
}

.projects_link {
  h6 {
    background: #3059eb;
    color: $secondary;
    padding: 5px 10px;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    border-radius: 4px;
    cursor: pointer;
    img {
      padding-right: 5px;
    }
  }
}

.recent_transactions {
  //   padding-top: 3rem;
  padding-bottom: 0.1rem;
  h5 {
    font-family: Gotham-bold;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    display: flex;
  }
}

.green-dot {
  width: 1rem;
  height: 1rem;
  border-radius: 100px;
  background: #2fc643;
  margin: auto;
  margin-top: 3px;
}
.yellow-dot {
  width: 1rem;
  height: 1rem;
  border-radius: 100px;
  background: #ffb100;
  margin: auto;
  margin-top: 3px;
}
.red-dot {
  width: 1rem;
  height: 1rem;
  border-radius: 100px;
  background: #ea0000;
  margin: auto;
  margin-top: 3px;
}

.table-wrap {
  background: $secondary;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 40px;
}
.seperate_border {
  border-collapse: separate;
  border-spacing: 0px 15px;
}

.seperate_border {
  th,
  td {
    border-spacing: 0 15px;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    text-align: left;
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    // line-height: 30px;
    color: #6c6c6c;
    white-space: nowrap;
  }
  th:first-child {
    border-left: 1px solid #e5e5e5;
    border-radius: 10px 0 0 10px;
  }
  th:last-child {
    border-right: 1px solid #e5e5e5;
    border-radius: 0px 10px 10px 0px;
    font-weight: bold !important;
    color: $black !important;
  }
}

@media screen and (max-width: 599px) {
}

// pagination ends
</style>
